/* .fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
} */

.banner {
  background-size: cover;
  background-position: center;
  height: 550px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
}
.banner__button-link {
  text-decoration: none;
}
.banner__contents {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 160px;
  height: 190px;
  z-index: 1;
}

.banner__title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.5rem;
  text-shadow: 1px 1px 3px #000000ad;
}
.banner__description {
  font-size: 1rem;
  line-height: 1.3;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 360px;
  height: 80px;
  text-shadow: 1px 1px 3px #000000ad;
}
.banner__buttons {
  display: flex;
  margin-right: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0 0.2rem;
  box-sizing: border-box;
}
.banner__button {
  display: flex;
  outline: none;
  border: none;
  font-weight: 700;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 0.2rem;
  margin-right: 1rem;
  background-color: rgb(6 5 5 / 50%);
  color: #fff;
  cursor: pointer;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.banner__button:hover {
  background-color: #e6e6e6;
  color: #000;
  transition: all 0.2s ease-in-out;
}
.banner__fadeBottom {
  height: 22.4rem;
  background-image: linear-gradient(
    180deg,
    transparent 0%,
    rgba(24, 24, 24, 0.61) 60%,
    #0a0a0a 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.banner__button-play-icon {
  font-size: 30px;
}
.banner__button-more-icon {
  font-size: 30px;
}
.banner__button-text {
  margin: 0;
  padding-left: 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
}
.banner__button-white {
  background-color: #fff;
  color: #000;
}
.banner__button-icon-white {
  -webkit-filter: invert(1);
  filter: invert(1);
}
.banner__button-icon {
  font-size: 30px;
}
.banner__button:hover .banner__button-icon-white {
  -webkit-filter: none;
  filter: none;
}
@media screen and (max-width: 479px) {
  .banner__button {
    padding: 0.7rem 1.2rem;
  }
  .banner__button-text {
    font-size: 1rem;
  }
  .banner__button-play-icon {
  }
  .banner__button-more-icon {
  }
  .banner__title {
    font-size: 2.3rem;
  }
  .banner__button-text {
    font-size: 0.8rem;
  }
}
