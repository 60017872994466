.search {
  padding: 120px 30px;
}
.search__title {
  color: #fff;
}
.search__posters {
  display: grid;
  grid-gap: 10px;
  margin-top: 40px;
  padding: 0;
  list-style: none;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
@media screen and (max-width: 540px) {
  .search__posters {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 375px) {
  .search__posters {
    grid-template-columns: 1fr;
  }
}
