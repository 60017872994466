.about {
  box-sizing: border-box;
  padding: 0 30px;
  margin: 100px auto;
  max-width: 1080px;
  text-align: center;
}
.about__info {
  margin-top: 60px;
  box-sizing: border-box;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
  line-height: 1;
  border-radius: 10px;
}
.about__image {
  width: 100%;
  max-width: 300px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 3px solid #000;
  outline: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.about__image:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.about__author {
  margin-bottom: 10px;
}
.about__author {
  display: flex;
  flex-direction: column;
}
.about__project {
  width: 100%;
}
.about__project-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
}
.about__project-description {
  margin: 0;
  font-size: 16px;
  line-height: 1.5rem;
  padding: 0;
  color: #fff;
}
.about__developer {
  width: 100%;
  line-height: 1.5rem;
  margin-top: 60px;
}

.about__skills {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.about__skills-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.about__skill {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.about__progress-bar {
  height: 8px;
  background-color: #24232357;
  overflow: hidden;
  width: 200px;
  margin-left: 10px;
}

.about__progress-bar-fill {
  height: 100%;
  background-color: #ffffff;
  width: 0;
  transition: width 1s ease-in-out;
}

.about__progress-bar-fill.loaded {
  width: 100%;
}
.about__socials {
  position: relative;
}
.about__social {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.about__social-icon {
  color: #000;
  font-size: 24px;
  position: relative;
  background: #fff;
  border-radius: 50px;
  padding: 3px;
  box-shadow: 0px 0 11px 1px #00000061;
}
.about__facebook {
  bottom: 40px;
}

.about__linkedin {
  bottom: 16px;
}
.about__whatsapp {
  bottom: 40px;
}
.about__github {
  bottom: 16px;
}
.about__instagram {
  bottom: 9px;
}
.about__short-note {
  margin: 0;
  font-size: 16px;
  max-width: 500px;
  line-height: 1.5rem;
  padding: 0;
}

.about___developer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.about__developer-logo {
  width: 138px;
}
@media screen and (max-width: 768px) {
  .about__info {
    flex-direction: column;
  }
  .about___developer {
    flex-direction: column;
  }
  .about__image {
    width: 180px;
  }
}
