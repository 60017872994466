.trailer__title {
  margin: 0;
  padding-left: 30px;
}
.trailer__posters {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 30px 24px;
  list-style-type: none;
  -webkit-overflow-scrolling: touch;
  cursor: -webkit-grab;
  cursor: grab;
}
.trailer__posters:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.trailer__posters::-webkit-scrollbar {
  /* display: none; */
  width: 5px;
}

/* Track */
.trailer__posters::-webkit-scrollbar-track {
  background: #0a0a0a;
}

/* Handle */
.trailer__posters::-webkit-scrollbar-thumb {
  background: #0a0a0a;
  border-radius: 5px;
}

/* Handle on hover */
.trailer__posters::-webkit-scrollbar-thumb:hover {
  background: #0a0a0a;
}

@media screen and (max-width: 479px) {
  .trailer__posters {
    padding: 40px 0 40px 24px;
  }
}
