.search-popup {
  box-sizing: border-box;
  background: #000;
  width: 100%;
  margin: 0 auto;
}
.search-popup__container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border: 1px solid #545454;
  border-radius: 20px;
}
.search-popup__input {
  width: 100%;
  background: transparent;
  border-radius: 20px 0 0 20px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  font-family: "Inter", Arial, sans-serif;
}
.search-popup__link {
  padding: 10px;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  cursor: pointer;
}
.search-popup__button {
  background-color: transparent;
  -webkit-filter: invert(1);
  filter: invert(1);
  cursor: pointer;
  border: none;
}
