.footer {
  /* max-width: 880px; */
  margin: 0 auto;
  transition: 0.3s;
}
.footer__copyright {
  margin: 0;
  color: #545454;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  padding: 60 30px 60px 30px;
  font-family: "Inter", Arial, sans-serif;
}

@media screen and (max-width: 540px) {
  .footer__copyright {
    line-height: 17px;
    padding: 60px 30px 36px;
  }
}
