.card {
  position: relative;
  min-width: 182px;
  height: auto;
  border-radius: 4px;
  background-color: #fff;
  transition: 450ms;
}

.card:hover {
  transform: scale(1.2);
  z-index: 1;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
}
.card_bg {
  min-width: 300px;
  transition: 450ms;
}
.card_bg:hover {
  transform: scale(1.12);
  z-index: 1;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
}
.card__image {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card__place-title {
  margin: 25px 15px 31px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__place-name {
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
  font-size: 24px;
  font-weight: 900;
  text-align: left;
}

.card__place-favorite {
  background-image: url(../../images/heart.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  min-width: 21px;
  height: 19px;
  transition: 0.2s;
  color: transparent;
  border: none;
}
.card__links {
  margin: 0;
}
.card__title {
  margin: 0;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
}
.card__title_sm {
  font-size: 12px;
  font-weight: 400;
  line-height: 1rem;
}
.card__vote-average {
  margin: 0;
  color: #46d369;
}
.card__vote-average_sm {
  font-size: 12px;
}
.card__info {
  opacity: 0;
  z-index: -1;
  box-sizing: border-box;
  text-decoration: none;
  background: #000000e5;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 10px;
  transition: 0.3s;
}
.card__info_lg {
  padding: 10px 20px;
}

.card:hover .card__info {
  opacity: 1;
  z-index: 1;
}
.card__info-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 5px 0;
}
.card__play {
  width: 23px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
}
.card__play_bg {
  width: 30px;
}
.card__view-more {
  width: 23px;
  padding: 10px;
  cursor: pointer;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  transform: rotate(-90deg);
}
.card__view-more_bg {
  width: 30px;
}

@media screen and (max-width: 749px) {
  .card {
    min-width: 160px;
  }
  .card_bg {
    min-width: 220px;
  }
}
@media screen and (max-width: 479px) {
  .card {
    min-width: 150px;
  }
  .card_bg {
    min-width: 200px;
  }
}
