.more-info-banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  z-index: 1;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
  padding: 0px 0 60px 0;
}

.more-info__banner-contents {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 150px;
  z-index: 1;
}

.more-info__banner-title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.5rem;
  text-shadow: 1px 1px 3px #000000ad;
}
.more-info__banner-description {
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  width: 100%;
  line-height: 1.3;
  max-width: 660px;
  text-shadow: 1px 1px 3px #000000ad;
}
.more-info__banner-info {
  display: flex;
  margin-top: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.2rem;
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
}
.more-info__banner-rating {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}
.more-info__banner-movie-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  text-shadow: 1px 1px 3px #000000ad;
}
.more-info__banner-info-block {
  transition: all 0.2s ease-in-out;
}
.more-info__banner-fadeBottom {
  height: 550px;
  background-image: linear-gradient(
    180deg,
    transparent 0%,
    rgba(24, 24, 24, 0.61) 60%,
    #0a0a0a 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.more-info__banner-rating-icon {
  width: 22px;
  -webkit-filter: invert(1);
  filter: invert(1);
  margin-top: -7px;
}
.more-info__banner-rating-text {
  font-size: 1rem;
}
.more-info__banner-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.more-info__banner-logo {
  max-width: 150px;
  max-height: 60px;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}
.more-info__banner-runtime {
}
.more-info__banner-rating-icon-white {
  -webkit-filter: invert(1);
  filter: invert(1);
}
.more-info__banner-genre-text {
  color: #fff;
}
.more-info__description {
  width: 100%;
  display: flex;
  transition: all 0.2s ease-in-out;
  padding: 2rem 2rem;
  gap: 40px;
  box-sizing: border-box;
}

.more-info__title {
  min-width: 100px;
  color: grey;
  font-family: "Inter", Arial, sans-serif;
}
.more-info__text {
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
}
.more-info__genres {
  display: flex;
  gap: 20px;
  font-family: "Inter", Arial, sans-serif;
  padding-top: 10px;
  flex-wrap: wrap;
}
.more-info__site {
  display: flex;
  gap: 20px;
  font-family: "Inter", Arial, sans-serif;
  padding-top: 10px;
  flex-wrap: wrap;
}
.more-info__production {
  display: flex;
  gap: 10px;
  font-family: "Inter", Arial, sans-serif;
  padding-top: 10px;
  flex-wrap: wrap;
}
.more-info__languages {
  display: flex;
  gap: 20px;
  font-family: "Inter", Arial, sans-serif;
  padding-top: 10px;
  flex-wrap: wrap;
}
.more-info__link {
  color: #fff;
  text-decoration: underline;
  font-family: "Inter", Arial, sans-serif;
  text-decoration: none;
}
.more-info__video {
  width: 50%;
  text-align: center;
  transition: 0.3s;
}
.more-info__details {
  width: 50%;
}

.more-info__youtube iframe {
  width: 100%;
}
@media screen and (max-width: 1080px) {
  .more-info__description {
    flex-direction: column;
  }
  .more-info__video {
    width: 100%;
  }
  .more-info__details {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .more-info__banner-description {
    font-size: 0.8rem;
    max-width: 360px;
  }
  .more-info__youtube iframe {
    height: 300px;
  }
}
