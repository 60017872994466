.nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: 0.3s;
}
.nav_scrolled {
  background-color: #0a0a0a;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
.nav__logo {
  width: 100px;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
}
.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin-right: 20px;
}
.nav__link {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.nav__avatar {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}
.nav__icon {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}
.nav__links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
}

.nav__about {
  color: #fff;
  padding: 0 0 0 20px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}
@media screen and (max-width: 540px) {
  .nav__logo {
    width: 78px;
  }
}
