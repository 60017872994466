.body {
  background: #0a0a0a;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0a0a0a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6a6a;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6b6a6a;
}
