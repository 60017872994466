.main {
  box-sizing: border-box;
  min-width: 320px;
  margin: 0 auto;
  padding-top: 45px;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
  line-height: 1;
}

@media screen and (max-width: 540px) {
  .main {
    padding-top: 28px;
  }
}
